import './index.css'
import { getGameDetail, startGame, collectGame, getDefaultAccount } from './service'
import { useEffect, useState, useRef, useContext } from 'react'
import { useParams } from 'react-router-dom'
import CustomImage from '../../components/image'
import CommonWindow from '../../components/window'
import PubSub from 'pubsub-js'
import { EventTip, EventLoginEffect } from '../../common/event'
import useNeedAuth from '../../hooks/useNeedAuth'
import AppContext from '../../common/context'
import Queue from '../queue'
// import Dialog from '../../components/dialog'
import NoAccount from '../../components/noaccount'
import { AnimatePresence, motion } from 'framer-motion'
import AccountProduct from '../../components/accountProduct'
import useSafeBack from '../../hooks/useSafeBack'
import AccountList from '../../components/accountList'
import { SlideFromBottomAuto } from '../../animation'
import TimeDialog from '../../components/timeDialog'
import { getSystemConfig } from '../../common/service'
import Auth from '../../components/auth'
import Service from '../service'

function Game() {
    const { setGame, isLogin, toggleLoginPage, toggleWaiting, setWaitingInfo, user, setSysConfig } = useContext(AppContext)
    const { gameId } = useParams()
    const [detail, setDetail] = useState({})
    const [ready, toggleReadyState] = useState(false)
    const [recommend, setRecommend] = useState([])
    const [collected, toggleCollectedState] = useState(false)
    const [showQueue, toggleQueue] = useState(false)
    const [queueInfo, setQueueInfo] = useState({})
    const [showTimeDialog, toggleTimeDialog] = useState(false)
    const [bindAccount, setBindAccount] = useState(null)
    const [accountRefreshHelper, setAccountRefreshHelper] = useState(0)
    const [showNoAccount, toggleNoAccount] = useState(false)
    const [showProduct, toggleProduct] = useState(false)
    const [accountPageIndex, setAccountPageIndex] = useState(0)
    const [showAccountList, toggleAccountList] = useState(false)
    const [showDescMoreBtn, toggleDescMoreBtn] = useState(false)
    const [showGameDesc, toggleGameDesc] = useState(false)
    const [showGameUploader, toggleGameUploader] = useState(false)
    const [showAuth, toggleAuth] = useState(false)
    const [showService, toggleService] = useState(false)
    const [startAccount, setStartAccount] = useState('')
    const collectFlag = useRef(false)
    const startFlag = useRef(false)
    const safeBack = useSafeBack()
    useNeedAuth()

    useEffect(() => {
        getGameDetail(gameId).then(data => {
            toggleCollectedState(data.data.collection === 1)
            setDetail(data.data)
            setRecommend(data.similarity)
            toggleReadyState(true)
        }).catch(e => {
            PubSub.publish(EventTip, { msg: e.message })
        })

        const listener = PubSub.subscribe(EventLoginEffect, () => {
            getGameDetail(gameId).then(data => {
                toggleCollectedState(data.data.collection === 1)
            })
        })

        return () => {
            PubSub.unsubscribe(listener)
        }
    }, [gameId])

    useEffect(() => {
        if (detail.atype === 2 && isLogin) {
            getDefaultAccount(gameId).then(data => {
                setBindAccount(data)
            }).catch(e => {
                PubSub.publish(EventTip, { msg: e.message })
            })
        }
    }, [gameId, detail, isLogin, accountRefreshHelper])

    useEffect(() => {
        if (detail.introduction) {
            const el = document.getElementById('app-game-desc')
            const box = el.parentNode
            const { height: contentHeight } = el.getBoundingClientRect()
            const { height: containerHeight } = box.getBoundingClientRect()
            contentHeight > containerHeight && toggleDescMoreBtn(true)
        }
    }, [detail.introduction])

    useEffect(() => {
        const listener = () => {
            toggleGameUploader(false)
        }

        document.addEventListener('click', listener)

        return () => {
            document.addEventListener('click', listener)
        }
    }, [])

    const closeTimeDialog = () => {
        toggleTimeDialog(false)
    }

    const closeQueue = () => {
        toggleQueue(false)
    }

    const closeAuth = () => {
        toggleAuth(false)
    }

    const start = async (account = '') => {
        // setQueueInfo({ isPeak: 0, queueNum: 1, queueSpeed: 1 })
        // toggleQueue(true)
        // return
        if (startFlag.current) {
            return
        }
        startFlag.current = true
        let needAuth = true
        try {
            const sysConfig = await getSystemConfig()
            setSysConfig(sysConfig)
            needAuth = sysConfig.realnameToH5
        } catch (e) {
            console.error(e)
        }
        if (needAuth && !user.realID) {
            startFlag.current = false
            return toggleAuth(true)
        }
        startGame(gameId, 0, account).then(data => {
            const { appChannel, ctoken, pkgName, playTime, protoData, utoken, isPeak: channel, tempTime, accountData } = data
            setGame({
                gameId,
                appChannel,
                ctoken,
                pkgName,
                playTime,
                protoData,
                utoken,
                tempTime,
                channel,
                accountData
            })
            setWaitingInfo({
                gameImg: detail.iconh,
                channel
            })
            toggleWaiting(true)
        }).catch(e => {
            if (e.code === 201) {
                const { isPeak, queueNum, queueSpeed } = e.data
                setQueueInfo({ isPeak, queueNum, queueSpeed })
                setStartAccount(account)
                toggleQueue(true)
            } else if (e.code === 202) {
                toggleTimeDialog(true)
            } else {
                PubSub.publish(EventTip, { msg: e.message })
            }
        }).finally(() => {
            startFlag.current = false
        })
    }

    const collect = () => {
        if (collectFlag.current) {
            return
        }
        if (!isLogin) {
            return toggleLoginPage(true)
        }
        collectFlag.current = true
        toggleCollectedState(!collected)
        collectGame(gameId, collected ? 0 : 1).then(() => {
            PubSub.publish(EventTip, { msg: `${collected ? '取消' : ''}收藏成功` })
        }).catch(e => {
            toggleCollectedState(collected)
            PubSub.publish(EventTip, { msg: e.message })
        }).finally(() => {
            collectFlag.current = false
        })
    }

    const closeNoAccount = () => {
        toggleNoAccount(false)
    }

    const goToBuyAccount = () => {
        closeNoAccount()
        toggleProduct(true)
    }

    const goToBindAccount = () => {
        closeNoAccount()
        setAccountPageIndex(1)
        toggleAccountList(true)
    }

    const closeAccountProduct = () => {
        toggleProduct(false)
    }

    const accountHelperAction = () => {
        if (!isLogin) {
            return toggleLoginPage(true)
        }
        if (!bindAccount) {
            return toggleNoAccount(true)
        }
        setAccountPageIndex(0)
        toggleAccountList(true)
    }

    const closeAccountList = () => {
        toggleAccountList(false)
    }

    const normalStart = () => {
        if (!isLogin) {
            return toggleLoginPage(true)
        }
        if (detail.atype !== 1) {
            if (!bindAccount) {
                return toggleNoAccount(true)
            }
            return start(bindAccount.account)
        }
        start()
    }

    const manualStart = () => {
        closeNoAccount()
        start()
    }

    const startFromAccountList = account => {
        closeAccountList()
        if (!account && !bindAccount) {
            return toggleNoAccount(true)
        }
        start((account || bindAccount).account)
    }

    const refreshBindAccount = () => {
        setAccountRefreshHelper(h => h + 1)
    }

    const showGameDescPop = () => {
        toggleGameDesc(true)
    }

    const closeGameDesc = () => {
        toggleGameDesc(false)
    }

    const showGameUploaderPop = e => {
        e.stopPropagation()
        toggleGameUploader(true)
    }

    const goToService = () => {
        toggleService(true)
    }

    const closeService = () => {
        toggleService(false)
    }

    return (
        <>
            <div className='app-page-container pt56'>
                {
                    ready && <div className='app-game-img-bg-wrapper'>
                        <CustomImage className='app-game-img-bg' src={detail.iconv}></CustomImage>
                    </div>
                }
                <div className='app-game-header'>
                    <i className='app-game-header-icon back' onClick={safeBack}></i>
                    <i className='app-game-header-icon service' onClick={goToService}></i>
                    <i className={collected ? 'app-game-header-icon collect collected' : 'app-game-header-icon collect'} onClick={collect}></i>
                </div>
                <div className='app-page-window'>
                    <div className='app-whole-page'>
                        <div className='app-game-upper-wrapper'>
                            <div className='app-game-base-info'>
                                <div className='app-game-img-wrapper'>
                                    {
                                        ready && <CustomImage className='app-game-main-img' src={detail.iconv}></CustomImage>
                                    }
                                </div>
                                <p className='app-game-name'>{detail.name}</p>
                                <div className='app-game-desc'>
                                    {
                                        showDescMoreBtn && <span className='app-game-desc-more' onClick={showGameDescPop}>...查看&gt;</span>
                                    }
                                    <p id='app-game-desc'>{detail.introduction}</p>
                                </div>
                                <p className='app-game-handle-tip'>支持手柄</p>
                                <div className='app-game-tags-wrapper'>
                                    <div className='app-game-uploader'>
                                        <p onClick={showGameUploaderPop}>由<i className='app-game-uploader-avatar'></i>分享</p>
                                        {
                                            showGameUploader && <div className='app-game-uploader-pop'>该游戏由平台玩家上传，仅供玩家学习交流之用，游戏作品版权归游戏原创作者所有，游戏资料信息来自互联网</div>
                                        }
                                    </div>
                                    <ul className='app-game-tags-ul'>
                                        {
                                            ready && detail.tag.map(item => (<li className='app-game-tag' key={item.id}>{item.name}</li>))
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className='app-game-banner-wrapper'>
                                <div className='app-game-banner-box'>
                                    {
                                        ready && detail.description.map((item, n) => (<div className='app-game-banner-item' key={n}>
                                            <CustomImage className='app-game-banner-item-img' src={item}></CustomImage>
                                        </div>))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='app-game-lower-wrapper'>
                            <p className='app-game-lower-title'>相似游戏</p>
                            <CommonWindow data={recommend}></CommonWindow>
                        </div>
                    </div>
                </div>
                <div className='app-game-action-wrapper'>
                    <div className='app-game-action-btn-wrapper'>
                        {
                            detail.status === 1 && detail.atype !== 1 && <div className='app-game-action-ah-wrapper' onClick={accountHelperAction}>
                                <i className='app-game-action-ah-icon'></i>
                                <p className='app-game-action-ah-title'>{bindAccount ? '已绑定' : '上号助手'}</p>
                                <p className='app-game-action-ah-state'>{bindAccount ? bindAccount.account : '未绑定'}</p>
                            </div>
                        }
                        <div className='app-game-action-start-wrapper'>
                            {
                                ready && detail.status !== 1 && <button className='app-game-action-start disabled'>游戏已下架</button>
                            }
                            {
                                detail.status === 1 && <button className='app-game-action-start' onClick={normalStart}>启动游戏</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {
                showQueue && <Queue queueInfo={queueInfo} close={closeQueue} gameId={gameId} gameImg={detail.iconh} account={startAccount}></Queue>
            }
            {
                showTimeDialog && <TimeDialog close={closeTimeDialog}></TimeDialog>
            }
            {
                (showNoAccount || showProduct || showAccountList || showGameDesc) && <div className='app-page-container dialog'></div>
            }
            <AnimatePresence>
                {
                    showNoAccount && <NoAccount close={closeNoAccount} buyFunc={goToBuyAccount} bindFunc={goToBindAccount} manualFunc={manualStart}></NoAccount>
                }
            </AnimatePresence>
            <AnimatePresence>
                {
                    showProduct && <AccountProduct close={closeAccountProduct} gameid={gameId} refreshBindAccount={refreshBindAccount}></AccountProduct>
                }
            </AnimatePresence>
            <AnimatePresence>
                {
                    showAccountList && <AccountList close={closeAccountList} gameid={gameId} gameName={detail.name} start={startFromAccountList} refreshBindAccount={refreshBindAccount} index={accountPageIndex}></AccountList>
                }
            </AnimatePresence>
            <AnimatePresence>
                {
                    showGameDesc && <div className='app-page-container layout'>
                        <motion.div className='app-game-desc-dialog-wrapper' {...SlideFromBottomAuto}>
                            <div className='app-game-desc-dialog-header'>
                                <p className='app-game-desc-dialog-title'>游戏简介</p>
                                <i className='app-game-desc-dialog-close' onClick={closeGameDesc}></i>
                            </div>
                            <div className='app-game-desc-dialog-content'>{detail.introduction}</div>
                        </motion.div>
                    </div>
                }
            </AnimatePresence>
            <AnimatePresence>
                {
                    showService && <Service close={closeService}></Service>
                }
            </AnimatePresence>
            {
                showAuth && <Auth close={closeAuth}></Auth>
            }
        </>
    )
}

export default Game