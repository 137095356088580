import AppChannel from "./channel";
import { HMAccessKeyId } from "../config";
import PubSub from "pubsub-js";
import { EventGameOver, EventGameStart, EventTip, EventQualityList, EventNoOperation } from "./event";

const IgnoreScene = ['delay', 'packetsLost', 'videoStreamInfo']

export const initPlayer = () => {
    try {
        console.log('-- Init Begin --')
        window.Cloudplay.initSDK({
            accessKeyID: HMAccessKeyId,
            channelId: AppChannel,
            onSceneChanged(sceneId, extraInfo) {
                if (IgnoreScene.includes(sceneId)) {
                    return
                }
                console.log(`-- HM Scene Callback:${sceneId} --`, extraInfo)
                if (sceneId === 'stop') {
                    PubSub.publish(EventGameOver, extraInfo)
                }
                if (sceneId === 'play') {
                    PubSub.publish(EventGameStart, extraInfo)
                }
                if (sceneId === 'resolutionList') {
                    PubSub.publish(EventQualityList, extraInfo)
                }
                if (sceneId === 'noInputTimeRemind') {
                    PubSub.publish(EventNoOperation, extraInfo)
                }
                if (sceneId === 'wait') {
                    PubSub.publish(EventTip, { msg: `当前排队${extraInfo.waitingPeople || 1}人，正在尝试连接` })
                }
            },
            MessageHandler(message) {
                console.log(`-- HM Message --`, message)
            }
        })
    } catch (e) {
        console.error('-- Init Failed --', e)
    }
}

export const startPlayer = (el, uId, uToken, pkgName, appChannel, cToken, playingtime, noInputTimeout, protoData, accountData, autoPlayAudio) => {
    window.Cloudplay.startGame(el, {
        userInfo: {
            uId,
            uToken
        },
        pkgName,
        appChannel,
        cToken,
        playingtime,
        configInfo: 'null',
        isPortrait: false,
        noInputTimeout,
        autoPlayAudio,
        protoData,
        ...(accountData && {
            extraData: {
                stringExtra: {
                    StartAppParams: JSON.stringify({ startAppParams: accountData })
                }
            }
        })
    })
}

export const stopPlayer = () => {
    window.Cloudplay.stopGame(result => {
        const { code, msg } = result
        if (code !== 0) {
            PubSub.publish(EventTip, { msg })
        }
    })
}

export const addPlayerTime = (playingTime, cToken, uId, uToken, callback) => {
    playingTime *= 1000
    window.Cloudplay.updateGameUID({
        playingTime,
        cToken,
        userInfo: {
            uId,
            uToken
        }
    }, result => {
        const { code, msg } = result
        if (code !== 0) {
            PubSub.publish(EventTip, { msg })
        } else {
            callback()
        }
    })
}

const OpMethodMap = {
    xbox: 0,
    keyboard: 1,
    NONE: 2
}
const OpMethodArr = ['xbox', 'keyboard', 'NONE']

export const getOpMethod = () => {
    const result = OpMethodMap[window.Cloudplay.getVirtualKeys()]
    return result === void 0 ? -1 : result
}

export const setOpMethod = index => {
    window.Cloudplay.setVirtualKeys(OpMethodArr[index])
}

export const toggleAudioPlay = bool => {
    window.Cloudplay.enableAudio(bool)
}

export const toggleVibrate = bool => {
    window.Cloudplay.enableVibrate(bool)
}

export const switchResolution = id => {
    window.Cloudplay.switchResolution(id)
}

export const resetNoInputTimer = () => {
    window.Cloudplay.resetInputTimer()
}

export const playerKeyDown = key => {
    window.Cloudplay.sendMessageToRom({
        inputOp: key,
        inputState: 2,
        value: 0
    })
}

export const playerMouseDown = dirDown => {
    window.Cloudplay.sendMessageToRom({
        inputOp: 515,
        inputState: 2,
        value: dirDown ? -1 : 1
    })
}

export const playerKeyUp = key => {
    window.Cloudplay.sendMessageToRom({
        inputOp: key,
        inputState: 3,
        value: 0
    })
}

export const playerMouseUp = dirDown => {
    window.Cloudplay.sendMessageToRom({
        inputOp: 515,
        inputState: 3,
        value: dirDown ? -1 : 1
    })
}