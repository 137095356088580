import './index.css'
import { Link } from 'react-router-dom'

function AppNav({ index }) {
    return (
        <div className='app-nav-wrapper'>
            <div className='app-navs-container'>
                <div className='app-nav-recommend'>
                    {
                        index === 0
                            ? <i className='icon active'></i>
                            : <Link to={`/`}><i className='icon'></i></Link>
                    }
                </div>
                <div className='app-nav-usercenter'>
                    {
                        index === 1
                            ? <i className='icon active'></i>
                            : <Link to={`/uc`}><i className='icon'></i></Link>
                    }
                </div>
            </div>
        </div>
    )
}

export default AppNav