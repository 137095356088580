import './index.css'
import '../../pages/pay/index.css'
import { useState, useEffect, useRef, useContext, useCallback } from 'react'
import AppContext from '../../common/context'
import { orderFreeCard } from './service'
import PubSub from 'pubsub-js'
import { EventTip, EventUserInvalid, EventPayStart, EventPayEnd } from '../../common/event'
import { motion } from 'framer-motion'
import { SlideFromBottom } from '../../animation'
import { getDayName } from '../../common/util'
import { getFreeCardList } from '../../pages/index/service'
import { goToPay } from '../../config'

const getFreeCardLabel = item => {
    // if (item.status === 0) {
    //     return <div className='app-free-card-pay-item-label'>售罄</div>
    // }
    if (Array.isArray(item.weekDay) && item.weekDay.length) {
        return <div className='app-free-card-pay-item-label onsale'>
            <span>仅</span>
            <span className='week'>{item.weekDay.map(getDayName).join('、')}</span>
            <span>可购买</span>
        </div>
    }
    return null
}

function FreeCard() {
    const { toggleFreeCardEntry, toggleFreeCard, showFreeCardEntry, freeCardList, setFreeCardList, setAgreementType, toggleAgreement } = useContext(AppContext)
    const [chosen, setChosen] = useState({})
    const [payTypeIndex, setPayTypeIndex] = useState(0)
    const submitFlag = useRef(false)
    const close = useCallback(() => {
        toggleFreeCard(false)
    }, [toggleFreeCard])

    useEffect(() => {
        if (freeCardList.length) {
            setChosen(freeCardList.find(i => i.status === 1) || freeCardList[0])
        } else {
            getFreeCardList().then(data => {
                setFreeCardList(data)
            }).catch(e => {
                PubSub.publish(EventTip, { msg: e.message })
            })
        }
    }, [freeCardList, setFreeCardList])

    useEffect(() => {
        const listener = PubSub.subscribe(EventUserInvalid, close)
        const listener_ = PubSub.subscribe(EventPayEnd, close)

        return () => {
            PubSub.unsubscribe(listener)
            PubSub.unsubscribe(listener_)
        }
    }, [close])

    const goToBuy = () => {
        toggleFreeCardEntry(false)
    }

    const submit = () => {
        if (submitFlag.current) {
            return
        }
        submitFlag.current = true
        const ptype = payTypeIndex === 0 ? 2 : 1
        orderFreeCard(chosen.id, ptype).then(data => {
            goToPay(data.payResult, ptype)
            PubSub.publish(EventPayStart, { type: 3, orderid: data.orderid })
        }).catch(e => {
            PubSub.publish(EventTip, { msg: e.message })
        }).finally(() => {
            submitFlag.current = false
        })
    }

    const goToRechargeAgreement = () => {
        setAgreementType(3)
        toggleAgreement(true)
    }

    return showFreeCardEntry ? (
        <div className='app-page-container dialog zIndex2'>
            <div className='app-free-card-container'>
                <i className='app-free-card-close' onClick={close}></i>
                <button className='app-free-card-entry' onClick={goToBuy}></button>
            </div>
        </div>
    ) : (
        <motion.div className='app-page-container zIndex5' {...SlideFromBottom}>
            <div className='app-page-window'>
                <div className='app-whole-page app-free-card-pay-container'>
                    <i className='app-popup-close' onClick={close}></i>
                    <div>
                        {
                            freeCardList.map(item => (
                                <div className={item.id === chosen.id ? 'app-free-card-pay-item active' : 'app-free-card-pay-item'} key={item.id} onClick={() => setChosen(item)}>
                                    <span className='app-free-card-price'>&yen;<span className='value'>{item.androidPrice}</span></span>
                                    <div>
                                        <p className='app-free-card-name'>{item.name}</p>
                                        <p className='app-free-card-desc'>{item.remark}</p>
                                    </div>
                                    {getFreeCardLabel(item)}
                                </div>
                            ))
                        }
                    </div>
                    <div className='app-pay-types-wrapper index-4'>
                        <div className='app-pay-type' onClick={() => setPayTypeIndex(0)}>
                            <i className={payTypeIndex === 0 ? 'app-pay-type-checkbox checked' : 'app-pay-type-checkbox'}></i>
                            <i className='app-pay-type-ali-icon'></i>
                            <p className='app-pay-type-info'><i className='app-pay-type-icon ali'></i>支付宝</p>
                        </div>
                        <div className='app-pay-type' onClick={() => setPayTypeIndex(1)}>
                            <i className={payTypeIndex === 1 ? 'app-pay-type-checkbox checked' : 'app-pay-type-checkbox'}></i>
                            <p className='app-pay-type-info'><i className='app-pay-type-icon wechat'></i>微信</p>
                        </div>
                    </div>
                    <div className='app-free-card-promo'></div>
                    <p className='app-free-card-pay-tip'>每人每日限购1次，重复购买时长不叠加</p>
                    <div className='app-free-card-pay-frame'>
                        {
                            chosen.status === 1 && <button className='app-free-card-pay-btn' onClick={submit}>立即购买</button>
                        }
                        {
                            chosen.status === 0 && <button className='app-free-card-pay-btn disabled'>已售罄</button>
                        }
                        {
                            chosen.status === -1 && <button className='app-free-card-pay-btn disabled'>今日已购买</button>
                        }
                        <p className='app-free-card-pay-price'>
                            <span className='label'>支付&yen;</span>
                            <span className='value'>{chosen.androidPrice}</span>
                            <span className='original'>&yen;{chosen.oldPrice}</span>
                        </p>
                    </div>
                    <p className='app-free-card-pay-agreement'>支付即视为阅读并同意<span className='highlight' onClick={goToRechargeAgreement}>《用户充值协议》</span></p>
                </div>
            </div>
        </motion.div>
    )
}

export default FreeCard