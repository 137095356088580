import Http from '../../common/request'

export const getGameDetail = gameid => {
    return Http('/api/client/gameDetail', { gameid, auth: true, option: true })
}

export const startGame = (gameid, isPeak, account) => {
    return Http('/api/client/auth/game/open', { gameid, isPeak, auth: true, ...(account && { account }) })
}

export const collectGame = (gameid, collection) => {
    return Http('/api/client/auth/collection/save', { gameid, collection, auth: true })
}

export const getDefaultAccount = gameid => {
    return Http('/api/client/auth/game/bind/account', { auth: true, silent: true, gameid })
}